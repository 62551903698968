export const ACTIVITY_SECTOR = {
  Consulting: 'consulting',
  Healthcare: 'healthcare',
  PersonalServices: 'personal-services',
  Transport: 'transport',
  Construction: 'construction',
  ECommerce: 'e-commerce',
  RealEstate: 'real-estate',
  FoodAndRestoration: 'food-and-restoration',
  AccomodationAndTourism: 'accomodation-and-tourism',
  ArtsAndCulture: 'arts-and-culture',
  Other: 'other',
} as const;

export type ActivitySector = (typeof ACTIVITY_SECTOR)[keyof typeof ACTIVITY_SECTOR];
