import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom, map, Observable } from 'rxjs';
import { AccountingOnboardingStateService } from '@dougs/accounting/onboarding/shared';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { LoggerService } from '@dougs/core/logger';
import { RoutingService, URL } from '@dougs/core/routing';
import { StateService } from '@dougs/core/state';
import {
  LegalMeetingData,
  OnboardingCreationRequestData,
  OnboardingCreationStatusWritings,
  OnboardingCreationWorkflow,
  WORKFLOW_1_STEP_NAME,
} from '@dougs/onboarding-creation/dto';
import { ServicesStateService } from '@dougs/services/shared';
import { CUSTOMER_TASK_CODE, UserTasksStateService } from '@dougs/task/shared';
import { UserStateService } from '@dougs/user/shared';
import { OnboardingCreationMockHttpService } from '../http/onboarding-creation-mock.http';
import { OnboardingCreationHttpService } from '../http/onboarding-creation.http';

interface OnboardingCreationState {
  onboarding: OnboardingCreationWorkflow;
  statusWritings?: OnboardingCreationStatusWritings;
}

@Injectable({
  providedIn: 'root',
})
export class OnboardingCreationStateService extends StateService<OnboardingCreationState> {
  constructor(
    private readonly onboardingCreationHttpService: OnboardingCreationHttpService,
    private readonly onboardingCreationMockHttpService: OnboardingCreationMockHttpService,
    private readonly userStateService: UserStateService,
    private readonly logger: LoggerService,
    private readonly userTasksStateService: UserTasksStateService,
    private readonly servicesStateService: ServicesStateService,
    private readonly router: Router,
    private readonly routingService: RoutingService,
    private readonly companyStateService: CompanyStateService,
    private readonly accountingOnboardingStateService: AccountingOnboardingStateService,
  ) {
    super();
  }

  onboardingCreation$: Observable<OnboardingCreationWorkflow> = this.select((state) => state.onboarding);

  statusWritings$: Observable<OnboardingCreationStatusWritings | undefined> = this.select(
    (state) => state.statusWritings,
  );

  onboardingCreationLegalMeetingStep$ = this.onboardingCreation$.pipe(
    map((onboarding) => onboarding.steps[WORKFLOW_1_STEP_NAME.LegalMeeting]),
  );

  isProjectWizardCompleted$ = this.onboardingCreation$.pipe(
    map((onboarding) => onboarding.steps[WORKFLOW_1_STEP_NAME.ProjectWizard].completedAt !== null),
  );

  legalMeetingCalendlyUrl$ = this.userTasksStateService.tasks$.pipe(
    map((tasks) => tasks.find((task) => task.code === CUSTOMER_TASK_CODE.InitialCreationAppointment)),
    map((customerInitalCreationTask) => customerInitalCreationTask?.metadata?.calendlyUrl || null),
  );

  async refreshOnboardingCreation(delay = 0): Promise<void> {
    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        try {
          const onboarding: OnboardingCreationWorkflow = await lastValueFrom(
            this.onboardingCreationHttpService.getOnboarding(this.companyStateService.activeCompany.id),
          );

          this.setState({
            onboarding,
          });
        } catch (e) {
          this.logger.error(e);
        }

        resolve();
      }, delay);
    });
  }

  async refreshOnboardingCreationStatusWritings(company: Company): Promise<void> {
    try {
      const statusWritings: OnboardingCreationStatusWritings = await lastValueFrom(
        this.onboardingCreationHttpService.getStatusWritings(company.id),
      );

      this.setState({
        statusWritings,
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updateLegalMeetingStep(legalMeetingData: LegalMeetingData): Promise<void> {
    const onboarding: OnboardingCreationWorkflow = this.state.onboarding;

    const requestData: OnboardingCreationRequestData = {
      steps: {
        [WORKFLOW_1_STEP_NAME.LegalMeeting]: {
          data: {
            ...onboarding.steps[WORKFLOW_1_STEP_NAME.LegalMeeting].data,
            ...legalMeetingData,
          },
        },
      },
    };

    await this.updateOnboarding(requestData);
  }

  async updateOnboarding(onboardingCreationRequestData: OnboardingCreationRequestData): Promise<void> {
    try {
      this.setState({
        onboarding: await lastValueFrom(
          this.onboardingCreationHttpService.updateOnboarding(
            this.companyStateService.activeCompany.id,
            onboardingCreationRequestData,
          ),
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async requestLegalMeetingQuote(): Promise<void> {
    try {
      await lastValueFrom(
        this.onboardingCreationHttpService.requestLegalMeetingQuote(this.userStateService.activeUser.id),
      );
    } catch (e) {
      this.logger.error(e);
    }
  }

  async mockLegalAppointmentDone(companyId: number): Promise<void> {
    try {
      await lastValueFrom(this.onboardingCreationMockHttpService.mockLegalAppointmentDone(companyId));
      await this.refreshOnboardingCreation();
    } catch (e) {
      this.logger.error(e);
    }
  }

  async mockLegalCreationQuote(company: Company): Promise<void> {
    try {
      await lastValueFrom(this.onboardingCreationMockHttpService.mockLegalCreationQuote(company.id));
      await this.servicesStateService.refreshCompanyServices(company);
      await this.refreshOnboardingCreation();
      await this.companyStateService.refreshCompany(company.id);
      await this.accountingOnboardingStateService.refreshAccountingOnboarding(company.id);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async mockLegalCreationFinalization(company: Company): Promise<void> {
    try {
      await lastValueFrom(this.onboardingCreationMockHttpService.mockLegalCreationFinalization(company.id));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async mockLegalCreationTransitToAccounting(company: Company): Promise<void> {
    try {
      await lastValueFrom(this.onboardingCreationMockHttpService.mockLegalCreationTransitToAccounting(company.id));
      await this.router.navigate([this.routingService.createUrl([URL.ACCOUNTING])]);
    } catch (e) {
      this.logger.error(e);
    }
  }

  async activateCreationBundle(): Promise<boolean> {
    try {
      await lastValueFrom(
        this.onboardingCreationHttpService.activateCreationBundle(this.companyStateService.activeCompany.id),
      );
      return true;
    } catch (e) {
      this.logger.error(e);
      return false;
    }
  }
}
