import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';

import { SERVICE_PRODUCT_TYPE } from '@dougs/services/dto';
import { Pricing } from '../dto';
import { LegalCreationTotalPricingDto } from '../dto/legal-creation-total-pricing.dto';
import { QuoteHttpService } from '../http/quote.http';

type QuoteState = LegalCreationTotalPricingDto;

@Injectable({
  providedIn: 'root',
})
export class QuoteStateService extends StateService<QuoteState> {
  constructor(
    private readonly logger: LoggerService,
    private readonly quoteHttpService: QuoteHttpService,
  ) {
    super();
  }

  totalCreationPricing$: Observable<Pricing> = this.select((state) => state.total);

  legalAppointmentPricing$: Observable<Pricing> = this.select(
    (state) => state.details[SERVICE_PRODUCT_TYPE.LegalBusinessCreationAppointment],
  );

  companyCreationPricing$: Observable<Pricing> = this.select(
    (state) => state.details[SERVICE_PRODUCT_TYPE.LegalCreation],
  );

  async refreshLegalCreationPricing(companyId: number): Promise<void> {
    try {
      this.setState(await lastValueFrom(this.quoteHttpService.getLegalCreationPricing(companyId)));
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshLegalCreationPricingBundle(companyId: number): Promise<void> {
    try {
      this.setState(await lastValueFrom(this.quoteHttpService.getLegalCreationPricingBundle(companyId)));
    } catch (e) {
      this.logger.error(e);
    }
  }
}
