export const PROJECT_WIZARD_STEP_NAME = {
  ActivitySector: 'activity-sector',
  ParticularLegalForm: 'particular-legal-form',
  SingleOrMultiple: 'single-or-multiple',
  Headquarter: 'headquarter',
  Nationality: 'nationality',
  Associates: 'associates',
  MaritalStatus: 'marital-status',
} as const;

export type ProjectWizardStepName = (typeof PROJECT_WIZARD_STEP_NAME)[keyof typeof PROJECT_WIZARD_STEP_NAME];
