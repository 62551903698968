import { OnboardingCreationWorkflow } from '../dto';
import { LANG_CHOICE, PROJECT_WIZARD_STEP_NAME, WORKFLOW_1_STEP_NAME, WORKFLOW_TYPE } from '../enums';

const getDefaults = (): OnboardingCreationWorkflow => ({
  type: WORKFLOW_TYPE.Workflow1,
  steps: {
    stepsOrder: [
      WORKFLOW_1_STEP_NAME.Offer,
      WORKFLOW_1_STEP_NAME.LegalMeeting,
      WORKFLOW_1_STEP_NAME.Launch,
      WORKFLOW_1_STEP_NAME.StatusWritings,
    ],
    [WORKFLOW_1_STEP_NAME.Offer]: {
      data: {
        isValidated: null,
      },
      completedAt: null,
    },
    [WORKFLOW_1_STEP_NAME.ProjectWizard]: {
      stepsOrder: [
        PROJECT_WIZARD_STEP_NAME.ActivitySector,
        PROJECT_WIZARD_STEP_NAME.ParticularLegalForm,
        PROJECT_WIZARD_STEP_NAME.Headquarter,
        PROJECT_WIZARD_STEP_NAME.SingleOrMultiple,
        PROJECT_WIZARD_STEP_NAME.Associates,
        PROJECT_WIZARD_STEP_NAME.MaritalStatus,
        PROJECT_WIZARD_STEP_NAME.Nationality,
      ],
      data: {
        [PROJECT_WIZARD_STEP_NAME.ActivitySector]: {
          completedAt: null,
          data: {
            activitySector: null,
          },
        },
        [PROJECT_WIZARD_STEP_NAME.ParticularLegalForm]: {
          completedAt: null,
          data: {
            legalInitialAppointmentType: null,
          },
        },
        [PROJECT_WIZARD_STEP_NAME.Headquarter]: {
          completedAt: null,
          data: {
            headquarter: null,
          },
        },
        [PROJECT_WIZARD_STEP_NAME.SingleOrMultiple]: {
          completedAt: null,
          data: {
            associatesChoice: null,
          },
        },
        [PROJECT_WIZARD_STEP_NAME.Associates]: {
          completedAt: null,
          data: {
            associates: null,
          },
        },
        [PROJECT_WIZARD_STEP_NAME.MaritalStatus]: {
          completedAt: null,
          data: {
            isMarriedOrHasCivilPartnership: null,
          },
        },
        [PROJECT_WIZARD_STEP_NAME.Nationality]: {
          completedAt: null,
          data: {
            nationality: null,
          },
        },
      },
      completedAt: null,
    },
    [WORKFLOW_1_STEP_NAME.LegalMeeting]: {
      data: {
        client: null,
        collaborator: null,
        date: null,
        isQuoteAvailable: false,
        lang: LANG_CHOICE.FR,
        isLegalAppointmentDone: false,
      },
      completedAt: null,
    },
    [WORKFLOW_1_STEP_NAME.Launch]: {
      data: {
        isValidated: null,
      },
      completedAt: null,
    },
    [WORKFLOW_1_STEP_NAME.StatusWritings]: {
      data: {
        isCreated: false,
      },
      completedAt: null,
    },
  },
});

export const getOnboardingCreationWorkflowMock = (
  p?: Partial<OnboardingCreationWorkflow>,
): OnboardingCreationWorkflow => ({
  ...getDefaults(),
  ...p,
});
