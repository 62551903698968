import { WORKFLOW_1_STEP_NAME } from '../enums';
import { PROJECT_WIZARD_STEP_NAME } from '../enums/project-wizard-step-name.enum';
import { LaunchData } from './workflows/workflow-1/steps/launch-data.dto';
import { LegalMeetingData } from './workflows/workflow-1/steps/legal-meeting/legal-meeting-data.dto';
import { OfferData } from './workflows/workflow-1/steps/offer-data.dto';
import { AssociatesData } from './workflows/workflow-1/steps/project-wizard/associates-data.dto';
import { HeadquarterData } from './workflows/workflow-1/steps/project-wizard/headquarter-data.dto';
import { MaritalStatusData } from './workflows/workflow-1/steps/project-wizard/marital-status-data.dto';
import { NationalityData } from './workflows/workflow-1/steps/project-wizard/nationality-data.dto';
import { ParticularLegalFormData } from './workflows/workflow-1/steps/project-wizard/particular-legal-form-data.dto';
import { SingleOrMultipleData } from './workflows/workflow-1/steps/project-wizard/single-or-multiple-data.dto';
import { StatusWritingsData } from './workflows/workflow-1/steps/status-writings-data.dto';

export type OnboardingCreationRequestData = {
  steps: {
    [WORKFLOW_1_STEP_NAME.ProjectWizard]?: {
      data: {
        [PROJECT_WIZARD_STEP_NAME.ParticularLegalForm]?: {
          data: ParticularLegalFormData;
        };
        [PROJECT_WIZARD_STEP_NAME.Nationality]?: {
          data: NationalityData;
        };
        [PROJECT_WIZARD_STEP_NAME.Associates]?: {
          data: AssociatesData;
        };
        [PROJECT_WIZARD_STEP_NAME.Headquarter]?: {
          data: HeadquarterData;
        };
        [PROJECT_WIZARD_STEP_NAME.SingleOrMultiple]?: {
          data: SingleOrMultipleData;
        };
        [PROJECT_WIZARD_STEP_NAME.MaritalStatus]?: {
          data: MaritalStatusData;
        };
      };
    };
    [WORKFLOW_1_STEP_NAME.Offer]?: {
      data: OfferData;
    };
    [WORKFLOW_1_STEP_NAME.LegalMeeting]?: {
      data: LegalMeetingData;
    };
    [WORKFLOW_1_STEP_NAME.Launch]?: {
      data: LaunchData;
    };
    [WORKFLOW_1_STEP_NAME.StatusWritings]?: {
      data: StatusWritingsData;
    };
  };
};
